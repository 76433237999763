import styled from '@emotion/styled';
import Switch from 'antd/lib/switch';
import { FC, HTMLAttributes } from 'react';

import { FormBlock } from '../common/form-block';

type TProps = {
  value: boolean;
  setValue?: (value: boolean) => void;
  label: string;
};

const Label = styled('span')`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  color: ${({ theme }) => theme.COLORS.WHITE._100};
`;

const Wrapper = styled(FormBlock)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  min-width: 420px;
  max-width: 420px;
  @media (max-width: 1230px) {
    min-width: 310px;
    max-width: 310px;
  }
  @media (max-width: 989px) {
    max-width: 100%;
  }
`;

export const CommonSelect: FC<TProps & HTMLAttributes<HTMLDivElement>> = ({
  value,
  setValue,
  label,
  ...props
}) => {
  return (
    <Wrapper
      onClick={() => {
        if (setValue) {
          setValue(!value);
        }
      }}
      {...props}
    >
      <Label>{label}</Label>
      <Switch checked={value} />
    </Wrapper>
  );
};
