import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconBaseProps } from '../../types/common';

const StatIcon: FC<TIconBaseProps> = ({ isGreen = false }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.2799 13.4805C23.7214 13.4805 24.0747 13.9031 23.9864 14.3257C23.6773 16.9552 22.485 19.2561 20.7629 20.9465C20.4979 21.2283 20.0563 21.2283 19.7914 20.9465L12.8144 13.4805H23.2799ZM22.5733 10.4752C22.6175 10.8979 22.2642 11.2266 21.8668 11.2266H12.0196V0.755282C12.0196 0.332674 12.3287 -0.0429764 12.7261 0.00397995C18.0251 0.379631 22.2201 4.84049 22.5733 10.4752ZM9.90003 13.4805L16.7445 20.8056C17.0536 21.1343 17.0536 21.6978 16.7003 21.9326C15.0223 23.2474 12.9469 23.9987 10.739 23.9987C5.04267 24.0926 0.185309 19.1622 0.00867755 13.1518C-0.212112 7.28222 3.80625 2.39875 9.10519 1.6005C9.50261 1.55354 9.90003 1.92919 9.90003 2.3518V13.4805Z"
        fill={isGreen ? '#39D5C9' : '#F6F6F6'}
      />
    </svg>
  );
};

export default withTheme(StatIcon);
