import styled from '@emotion/styled';
import { Input, Typography } from 'antd';
import { ChangeEventHandler, FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { LocaleKeys } from '../../locale';
import { useAppDispatch } from '../../state';
import { createVideoSetSecondFormAction } from '../../state/video/createSlice';
import { useCreateVideoSelector } from '../../state/video/selectors';
import Rewrite from '../icons/ rewrite';
import ApproveIcon from '../icons/approve';

const UpSide = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const ButtonWrapper = styled('span') <{ isApproved: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 12px;
  padding-top: ${({ isApproved }) => (isApproved ? '14px' : '12px')};
  padding-bottom: ${({ isApproved }) => (isApproved ? '14px' : '12px')};
  background-color: ${({ theme }) => theme.COLORS.BLACK._400};
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    padding-inline: 11px;
    padding-top: ${({ isApproved }) => (isApproved ? '13px' : '11px')};
    padding-bottom: ${({ isApproved }) => (isApproved ? '13px' : '11px')};
    border: 1px solid ${({ theme }) => theme.COLORS.ACCENT._400};
  }
`;

const Text = styled(Typography.Text)`
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  color: ${({ theme }) => theme.COLORS.BLACK._700};
`;

const TitleInput = styled(Input)`
  font-weight: 700;
  margin: 12px 0;
`;

type TProps = {
  isCreateMode?: boolean;
  onSwitchMode?: () => void;
  isApproved?: boolean;
  isHaveEditutton?: boolean;
};

export const DescriptionEdit: FC<TProps> = ({
  isCreateMode,
  onSwitchMode,
  isApproved = false,
  isHaveEditutton = false
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation([LocaleKeys.VIDEO]);

  const {
    secondStage: { form },
  } = useCreateVideoSelector();

  const onChangeTitle: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (isCreateMode) {
        dispatch(
          createVideoSetSecondFormAction({
            title: e.target.value,
          })
        );
      }
    },
    [isCreateMode]
  );

  const onChangeDescription: ChangeEventHandler<HTMLTextAreaElement> =
    useCallback(
      (e) => {
        if (isCreateMode) {
          dispatch(
            createVideoSetSecondFormAction({
              description: e.target.value,
            })
          );
        }
      },
      [isCreateMode]
    );

  return (
    <div>
      <UpSide>
        <Text>{t('newVideo.title')}</Text>
        {isHaveEditutton && (
          <ButtonWrapper isApproved={isApproved} onClick={onSwitchMode}>
            {isApproved ? <ApproveIcon /> : <Rewrite />}
          </ButtonWrapper>
        )}
      </UpSide>
      <TitleInput
        placeholder={t('newVideo.titlePlaceholder')}
        className="themed"
        onChange={onChangeTitle}
        value={isCreateMode ? form.title : undefined}
      />
      <Input.TextArea
        placeholder={t('newVideo.descriptionPlaceholder')}
        autoSize={{
          minRows: 4,
          maxRows: 8,
        }}
        className="themed"
        onChange={onChangeDescription}
        value={isCreateMode ? form.description : undefined}
      />
    </div>
  );
};
