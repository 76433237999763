import React, { ReactNode, memo } from 'react';

import { cellStyle, openStyle, stickyColumnStyle } from './styles';

interface TableCellProps {
  content: any;
  canBeExpanded: boolean;
  onClick?: () => void;
  isSticky?: boolean;
  isLoading?: boolean | undefined;
  'data-is-open'?: boolean;
}

export const TableCell: (props: TableCellProps) => ReactNode = memo(
  ({ content, canBeExpanded, onClick, isSticky, isLoading, ...rest }) => {
    return (
      <td
        css={[
          cellStyle(canBeExpanded),
          isSticky && !isLoading && stickyColumnStyle,
          rest['data-is-open'] && openStyle,
        ]}
        onClick={onClick}
      >
        {content}
      </td>
    );
  }
);
