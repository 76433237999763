import styled from '@emotion/styled';
import { theme } from 'antd';
import { FC } from 'react';

import Rewrite from '../icons/ rewrite';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
`;

const StyledTitle = styled('span')`
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  color: ${({ theme }) => theme.COLORS.WHITE._100};
  margin: 0;
`;

const StyledParagraph = styled('span')<{ maxHeight: string }>`
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  color: ${({ theme }) => theme.COLORS.BLACK._700};
  margin: 0;
  overflow-y: auto;
  text-overflow: ellipsis;
  @media (min-width: 990px) {
    height: calc(${(props) => props.maxHeight} - 300px);
  }
  @media (max-width: 990px) {
    max-height: 75px;
  }
`;

const UpSide = styled('span')`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 30px;
`;
const ButtonWrapper = styled('span')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background-color: ${({ theme }) => theme.COLORS.BLACK._400};
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    padding: 11px;
    border: 1px solid ${({ theme }) => theme.COLORS.ACCENT._400};
  }
`;

interface DescriptionPreviewProps {
  title: string;
  content: string;
  onSwitchMode: () => void;
  maxHeight: string;
}

export const DescriptionPreview: FC<DescriptionPreviewProps> = ({
  title,
  content,
  onSwitchMode,
  maxHeight,
}) => {
  return (
    <Wrapper>
      <UpSide>
        <StyledTitle>{title}</StyledTitle>
        <ButtonWrapper onClick={onSwitchMode}>
          <Rewrite />
        </ButtonWrapper>
      </UpSide>
      <StyledParagraph maxHeight={maxHeight}>{content}</StyledParagraph>
    </Wrapper>
  );
};
