import { Theme } from '@emotion/react';
import { FC } from 'react';

export enum SecondaryPaths {
  BASE = '/', // НЕ МЕНЯТЬ
  PLAYLISTS = '/playlists',
  STATISTICS = '/stat',
  PLAYLIST_ADD_VIDEO = '/add-video',
  CREATE_LINK = '/create-link',
  EDIT_LINK = '/edit-link',
}

export enum Paths {
  BASE = '/',
  LOGIN = '/login',
  FORGOT_PASSWORD = '/password',
  FORGOT_COMPLETE = '/restore-password',
  REGISTRATION = '/registration',
  UPLOADED_VIDEOS = '/uploaded',
  UPLOADED_VIDEOS_PLAYLIST = '/uploaded/playlists',
  VIEWED_VIDEOS = '/viewed',
  VIEWED_PLAYLISTS = '/viewed/playlists',
  CALENDAR = '/calendar',
  ACCOUNT = '/account',
  VIDEO = '/video',
  VIDEO_CREATE = '/video/create',
  PLAYLIST = '/playlist',
  ANALYTICS = '/analytics',
  FINANCE = '/finance',
  PRIVACY_POLICY = '/privacy-policy',
  PUBLIC_OFFER = '/public-offer',
}

type TMenuPath = string | Paths;

export type TMenuItem = {
  path: TMenuPath;
  activePathTrigger: TMenuPath | TMenuPath[];
  Icon: FC;
  text: string;
  strongEqual?: boolean;
  disabled?: boolean;
};

export type TIconBaseProps = {
  theme: Theme;
  isGreen?: boolean
};
