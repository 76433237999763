import styled from '@emotion/styled';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { UserInfo } from '../components/account/user-info';
import { UserMenu } from '../components/account/user-menu';
import { PageHeader } from '../components/common/page-header';
import { LocaleKeys } from '../locale';

const Wrapper = styled('div')`
  min-height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 100px;
`;

const Inner = styled('div')`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DataWrapper = styled('div')``;

export const Account: FC = () => {
  const { t } = useTranslation([LocaleKeys.ACCOUNT]);
  return (
    <Wrapper>
      <PageHeader>{t('header.account')}</PageHeader>
      <Inner>
        <DataWrapper>
          <UserInfo />
          <UserMenu />
        </DataWrapper>
      </Inner>
    </Wrapper>
  );
};
