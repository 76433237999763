import axios, { AxiosResponse } from 'axios';

import { TPlayListCreateForm } from '../modals/video/playlist-create-modal';
import {
  TDynamicData,
  TDynamicFilters,
  TExcelDownloadPayload,
} from '../state/analytics/dynamicsSlice';
import {
  TAnalyticsFilter,
  TAnalyticsResponse,
} from '../types/responses/analytics';
import { TUser } from '../types/responses/users';
import {
  TCreatePreviewPayload,
  TCreatePreviewResponse,
  TCreatedVideo,
  TLinkChunkStat,
  TLinkForm,
  TLinkListed,
  TLinkStat,
  TListedPlaylist,
  TPlayListEditForm,
  TPlayListItem,
  TPlaylistQueryParam,
  TUploadByLinkResponse,
  TUploadVideoResponse,
  TVideoCreateForm,
  TVideoCreatePayload,
  TVideoResponse,
} from '../types/responses/video';
import {
  TAuthResponse,
  TForgotLoginForm,
  TForgotPasswordCompletePayload,
  TLoginForm,
  TMeResponse,
} from '../types/users';

export const BASE_API_PATH =
  process.env.REMOTE_HOST ?? 'https://playai-backend.aoneiro.com/';

export const TOKEN_LS_PATH = 'plai-access';

const baseAPIInstance = axios.create({
  baseURL: BASE_API_PATH,
});

baseAPIInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem(TOKEN_LS_PATH);

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export const API = {
  videos: {
    upload: (file: FormData) => {
      return baseAPIInstance.post('/video/upload', file) as Promise<
        AxiosResponse<TUploadVideoResponse>
      >;
    },
    uploadWithUrl: (link: string) => {
      return baseAPIInstance.post(`/video/upload-by-url`, undefined, {
        params: {
          url: link,
        },
      }) as Promise<AxiosResponse<TUploadByLinkResponse>>;
    },
    addWithoutSave: (link: string) => {
      return baseAPIInstance.post(
        '/video/add-by-url-without-upload',
        undefined,
        {
          params: {
            url: link,
          },
        }
      ) as Promise<AxiosResponse<TUploadByLinkResponse>>;
    },
    getAll: () => {
      return baseAPIInstance.get('/video/list') as Promise<
        AxiosResponse<TCreatedVideo[]>
      >;
    },
    create: (data: TVideoCreatePayload) => {
      return baseAPIInstance.post('videos/setting', data);
    },
    createVideo: (data: TVideoCreateForm) => {
      return baseAPIInstance.post('video/create', data) as Promise<
        AxiosResponse<string>
      >;
    },
    editVideo: (data: Partial<TVideoCreateForm>, videoId: string) => {
      return baseAPIInstance.patch(`video/${videoId}`, data) as Promise<
        AxiosResponse<string>
      >;
    },
    createPreview: (data: TCreatePreviewPayload) => {
      return baseAPIInstance.post('video/createPreview', data) as Promise<
        AxiosResponse<TCreatePreviewResponse>
      >;
    },
    getById: (id: string) => {
      return baseAPIInstance.get(`video/${id}`) as Promise<
        AxiosResponse<TVideoResponse>
      >;
    },
    deleteById: (id: string) => {
      return baseAPIInstance.delete(`video/${id}`) as Promise<AxiosResponse>;
    },
    createLink: (
      data: { videoId: string; statisticsId?: string },
      form: TLinkForm,
      description?: string
    ) => {
      return baseAPIInstance.post('link', {
        videoId: data.videoId,
        statisticsId: data.statisticsId ?? '123',
        description: description ?? '',
        videoSetting: {
          duration: 0,
          ...form,
        },
      });
    },
    editLink: (
      data: { videoId: string; statisticsId?: string },
      form: TLinkForm,
      linkId: string,
      description?: string
    ) => {
      return baseAPIInstance.patch(`link/${linkId}`, {
        videoId: data.videoId,
        statisticsId: data.statisticsId ?? '123',
        description: description ?? '',
        videoSetting: {
          duration: 0,
          ...form,
        },
      });
    },
    deleteLink: (id: string) => {
      return baseAPIInstance.delete(`link/${id}`) as Promise<AxiosResponse>;
    },
    getLinksByVideo: (videoId: string) => {
      return baseAPIInstance.get(`link/list-by-video/${videoId}`) as Promise<
        AxiosResponse<TLinkListed[]>
      >;
    },
    getViewed: () => {
      return baseAPIInstance.get('history/videos') as Promise<
        AxiosResponse<TVideoResponse[]>
      >;
    },
    getAllLinks: () => {
      return baseAPIInstance.get('/link/self') as Promise<
        AxiosResponse<TLinkListed[]>
      >;
    },
    getLinChunkskStat: (id: string, userId: string) => {
      if (userId)
        return baseAPIInstance.get(
          `/chunks/heat-map?linkId=${id}&userId=${userId}`
        ) as Promise<AxiosResponse<TLinkChunkStat[]>>;

      return baseAPIInstance.get(`/chunks/heat-map?linkId=${id}`) as Promise<
        AxiosResponse<TLinkChunkStat[]>
      >;
    },
    getLinkStat: (id: string, userId: string) => {
      if (userId)
        return baseAPIInstance.get(
          `/stats/user-stats-by-link-for-owner?linkId=${id}&userId=${userId}`
        ) as Promise<AxiosResponse<TLinkStat>>;

      return baseAPIInstance.get(
        `/stats/link-stats-by-id?linkId=${id}`
      ) as Promise<AxiosResponse<TLinkStat>>;
    },
  },
  playlists: {
    create: (data: TPlayListCreateForm) => {
      return baseAPIInstance.post('playlist', {
        ...data,
        videoIds: [],
      }) as Promise<AxiosResponse<TPlayListItem>>;
    },
    getAll: (params: TPlaylistQueryParam) => {
      return baseAPIInstance.get('playlist/list', {
        params,
      }) as Promise<AxiosResponse<TListedPlaylist[]>>;
    },
    getById: (id: string) => {
      return baseAPIInstance.get(`playlist/${id}`) as Promise<
        AxiosResponse<TPlayListItem>
      >;
    },
    edit: (data: TPlayListEditForm, id: string) => {
      return baseAPIInstance.patch(`playlist/${id}`, data) as Promise<
        AxiosResponse<TListedPlaylist>
      >;
    },
    delete: (id: string) => {
      return baseAPIInstance.delete(`playlist/${id}`) as Promise<AxiosResponse>;
    },
    getViewed: () => {
      return baseAPIInstance.get('history/playlists') as Promise<
        AxiosResponse<TListedPlaylist[]>
      >;
    },
  },
  auth: {
    login: (data: TLoginForm) => {
      return baseAPIInstance.post('auth/login', data) as Promise<
        AxiosResponse<TAuthResponse>
      >;
    },
    registration: (data: TLoginForm) => {
      return baseAPIInstance.post('auth/registration', data) as Promise<
        AxiosResponse<TAuthResponse>
      >;
    },
    forgotPassword: (data: TForgotLoginForm) => {
      return baseAPIInstance.post('auth/restore-password', data);
    },
    forgotPasswordComplete: (data: TForgotPasswordCompletePayload) => {
      return baseAPIInstance.patch('auth/change-password-from-email', data);
    },
  },
  me: {
    getMyAccount: () => {
      return baseAPIInstance.get('auth/me') as Promise<
        AxiosResponse<TMeResponse>
      >;
    },
    // changeAvatar: (data: { profilePhotoUrl: string }) => {
    //   return baseAPIInstance.patch(
    //     'me/change-profile-photo',
    //     data
    //   ) as Promise<AxiosResponse>;
    // },
    patchMyUser: (
      data: Partial<{
        firstName: string | null;
        middleName: string | null;
        lastName: string | null;
      }>
    ) => {
      return baseAPIInstance.patch('me', data) as Promise<AxiosResponse>;
    },
    changePassword: (data: { oldPassword: string; newPassword: string }) => {
      return baseAPIInstance.patch(
        'me/change-password',
        data
      ) as Promise<AxiosResponse>;
    },
    changeEmail: (email: string) => {
      return baseAPIInstance.patch('me/change-email', {
        email,
      }) as Promise<AxiosResponse>;
    },
    deleteAccount: () => {
      return baseAPIInstance.delete('me/delete');
    },
  },
  users: {
    getAll: (
      params: { skip?: number; take?: number } = {
        skip: 0,
        take: 50,
      }
    ) => {
      return baseAPIInstance.get('/users', {
        params,
      }) as Promise<AxiosResponse<TUser[]>>;
    },
  },
  analytics: {
    getAll: (filters?: TAnalyticsFilter) => {
      return baseAPIInstance.get('/stats/all', {
        params: {
          ...filters,
        },
      }) as Promise<AxiosResponse<TAnalyticsResponse>>;
    },
    getDynamics: (filters: TDynamicFilters) => {
      return baseAPIInstance.get('/stats/dynamics', {
        params: {
          ...filters,
        },
      }) as Promise<AxiosResponse<TDynamicData>>;
    },
    downloadExcel: (data: TExcelDownloadPayload) => {
      return baseAPIInstance.get('/excel/download-stats', {
        params: data,
        responseType: 'blob',
      }) as Promise<AxiosResponse<Blob>>;
    },
  },
};
