import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  TLinkChunkStat,
  TLinkListed,
  TVideoResponse,
} from '../../types/responses/video';

type TState = {
  loading: boolean;
  loaded: boolean;
  errorOnLoad: boolean;
  data: TVideoResponse | undefined;
  linksData?: TLinkListed[];
};

const initialState: TState = {
  data: undefined,
  loaded: false,
  errorOnLoad: false,
  loading: false,
  linksData: undefined,
};

const byIdSlice = createSlice({
  name: 'video/by-id',
  initialState: initialState as TState,
  reducers: {
    setVideoByIdLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setVideoByIdError: (state, action: PayloadAction<boolean>) => {
      state.errorOnLoad = action.payload;
    },
    setVideoByIdAction: (
      state,
      action: PayloadAction<{
        videoData: TVideoResponse;
        linksData?: TLinkListed[];
      }>
    ) => {
      state.data = action.payload.videoData;
      state.linksData = action.payload.linksData;
      state.loaded = true;
      state.loading = false;
    },
    resetVideoStateAction: (state) => {
      state.data = undefined;
      state.loaded = false;
      state.errorOnLoad = false;
      state.loading = false;
      state.linksData = undefined;
    },
    addLinkAction: (state, action: PayloadAction<TLinkListed>) => {
      state.linksData = [...(state.linksData ?? []), action.payload];
    },
    deleteLinkAction: (state, action: PayloadAction<string>) => {
      state.linksData = [
        ...(state.linksData ?? []).filter((el) => el.id !== action.payload),
      ];
    },
  },
});

export const {
  setVideoByIdLoading,
  setVideoByIdError,
  setVideoByIdAction,
  resetVideoStateAction,
  addLinkAction,
  deleteLinkAction,
} = byIdSlice.actions;
export const videoByIdReducer = byIdSlice.reducer;
