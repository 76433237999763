import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconBaseProps } from '../../types/common';

const CopyIcon: FC<TIconBaseProps> = ({ theme }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0461 3.95484C18.4394 2.34811 16.5353 2.4366 15.7397 3.23221L15.7394 3.23255L13.6728 5.29762C13.1522 5.8179 12.3083 5.8176 11.788 5.29695C11.2677 4.77629 11.268 3.93244 11.7887 3.41215L13.8549 1.34742C13.855 1.34731 13.8551 1.34719 13.8552 1.34708C16.0799 -0.87712 19.7194 -0.141532 21.9309 2.07005C24.1426 4.28174 24.8782 7.92146 22.6536 10.1461L17.06 15.7396C14.8354 17.9643 11.1957 17.2287 8.98396 15.017C8.46349 14.4965 8.46349 13.6527 8.98396 13.1322C9.50443 12.6118 10.3483 12.6118 10.8688 13.1322C12.4755 14.739 14.3796 14.6505 15.1752 13.8549L20.7688 8.26128C21.5644 7.46567 21.6529 5.56157 20.0461 3.95484ZM13.1311 10.8684C11.5245 9.26182 9.6202 9.35023 8.82425 10.1462L3.23147 15.739C2.43553 16.5349 2.34712 18.4392 3.95371 20.0458C5.5603 21.6524 7.46459 21.564 8.26054 20.768L10.3254 18.7032C10.8458 18.1827 11.6897 18.1827 12.2102 18.7032C12.7306 19.2237 12.7306 20.0675 12.2102 20.588L10.1453 22.6528C7.9206 24.8776 4.28074 24.1424 2.06892 21.9306C-0.142906 19.7188 -0.878047 16.0789 1.34668 13.8542L6.93946 8.26139C9.16418 6.03667 12.8041 6.77178 15.0159 8.98361C15.5364 9.50408 15.5364 10.3479 15.0159 10.8684C14.4954 11.3889 13.6516 11.3889 13.1311 10.8684Z"
        fill={theme.COLORS.WHITE._100}
      />
    </svg>
  );
};

export default withTheme(CopyIcon);
