import styled from '@emotion/styled';

export const FormBlock = styled('div')`
  border-radius: 8px;
  height: 48px;

  background-color: ${({ theme }) => theme.COLORS.BLACK._400};
  padding-inline: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  color: ${({ theme }) => theme.COLORS.WHITE._600};
  text-align: center;
`;
