import styled from '@emotion/styled';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AnalyticsTable } from '../components/analytics/analytics-table';
import { FiltersList } from '../components/analytics/filters-list';
import { Statistics } from '../components/analytics/stat-cards';
import { PageHeader } from '../components/common/page-header';
import { LocaleKeys } from '../locale';

const Wrapper = styled.div`
  padding-inline: 16px;
`;

export const AnalyticsPage: FC = () => {
  const { t } = useTranslation([LocaleKeys.ANALYTICS]);

  return (
    <div>
      <PageHeader>{t('pageTitle')}</PageHeader>
      <Wrapper>
        <FiltersList />
        <Statistics />
        <AnalyticsTable />
      </Wrapper>
    </div>
  );
};
