import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconBaseProps } from '../../types/common';

const ApproveIcon: FC<TIconBaseProps> = ({ theme }) => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6652 0.620429C16.1116 1.06681 16.1116 1.79173 15.6652 2.2381L6.52338 11.3799C6.077 11.8263 5.35208 11.8263 4.9057 11.3799L0.334784 6.80902C-0.111595 6.36265 -0.111595 5.63773 0.334784 5.19135C0.781163 4.74497 1.50608 4.74497 1.95246 5.19135L5.71633 8.95164L14.0511 0.620429C14.4975 0.17405 15.2224 0.17405 15.6688 0.620429H15.6652Z"
        fill={theme.COLORS.ACCENT._100}
      />
    </svg>
  );
};

export default withTheme(ApproveIcon);
