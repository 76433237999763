import { css } from '@emotion/react';
import { theme } from 'antd';

export const tableContainerStyle = css`
  width: 100%;
  overflow-x: auto;
`;

const baseWidth = 1920;
const widthDifference = 900;

export const tableStyle = (fixHeight?: boolean) => css`
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  min-height: ${fixHeight ? '200px' : 'unset'};
  table-layout: fixed;

  td,
  th {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  @media (max-width: 900px) {
    width: ${baseWidth - widthDifference}px;
    margin-bottom: 100px;
  }
`;

export const headerStyle = css`
  background-color: #f4f4f4;
  color: #333;
`;

export const rowStyle = (expanded: boolean) => css`
  background-color: ${expanded ? '#242424' : '#151515'};
`;

export const cellStyle = (canExpanded: boolean) => css`
  padding: 8px;
  border-top: 1px solid #2e2e2e !important;
  border-bottom: 1px solid #2e2e2e !important;
  color: #fff;
  cursor: ${canExpanded ? 'pointer' : 'unset'};
  background-color: transparent;
`;

export const sortIconStyle = (isSorted: boolean) => css`
  transform: ${isSorted ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: transform 0.3s ease;
`;

export const openStyle = css`
  background-color: #242424;
`;

export const stickyColumnStyle = css`
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #151515;
  width: 420px;

  @media (max-width: 1200px) {
    width: 200px;
  }

  &::before {
    content: '';
    position: absolute;
    right: 10px;
    top: 8px;
    bottom: 8px;
    width: 1px;
    background-color: #2e2e2e;
  }
`;
