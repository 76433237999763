import { useCallback, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import queryString, { ParsedQuery } from "query-string";

type RequestQueryParams<T = unknown> = Record<string, T>;

export const useSearchParams = <T extends string | object>() => {
    const navigate = useNavigate();
    const location = useLocation();

    const query = useMemo(
        () => queryString.parse(location.search) as ParsedQuery<T>,
        [location.search],
    );

    const setParams = useCallback(
        (params: RequestQueryParams) => {
            const newQueryString = queryString.stringify(
                { ...query, ...params },
                { skipEmptyString: true },
            );

            navigate(
                {
                    search: newQueryString ? `?${newQueryString}` : "",
                },
                { replace: true },
            );
        },
        [navigate, query],
    );

    const resetParam = useCallback(
        (param: string) => {
            if (!query[param]) return;

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [param]: omittedParam, ...otherParams } = query;

            navigate(
                {
                    search: queryString.stringify(otherParams),
                },
                { replace: true },
            );
        },
        [query, navigate],
    );

    const removeAllParams = useCallback(() => {
        navigate(
            {
                search: "",
            },
            { replace: true },
        );
    }, [navigate]);

    const push = useCallback(
        (newQuery: RequestQueryParams, options?: ParsedQuery) => {
            navigate({
                search: queryString.stringify(
                    {
                        ...query,
                        ...newQuery,
                    },
                    options,
                ),
            });
        },
        [navigate, query],
    );

    return {
        setParams,
        resetParam,
        removeAllParams,
        query,
        push,
    };
};
