import { LoadingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button, Popconfirm } from 'antd';
import App from 'antd/lib/app';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { API } from '../api';
import { PageBackButton } from '../components/common/page-back-button';
import { DescriptionEdit } from '../components/video-page/description-edit';
import { DescriptionPreview } from '../components/video-page/description-preview';
import { LinksList } from '../components/video-page/links/links-list';
import { VideoPlayer } from '../components/video-player';
import { useLoadVideo } from '../hooks/use-load-video';
import {
  VIDEO_LINK_PARAM_NAME,
  useVideoPathId,
} from '../hooks/use-video-path-id';
import { LocaleKeys } from '../locale';
import mockVideo from '../mocks/mock-video.mp4';
import { useAppDispatch } from '../state';
import { resetVideoStateAction } from '../state/video/byIdSlice';
import { createVideoSetSecondFormAction } from '../state/video/createSlice';
import { resetVideoListState } from '../state/video/listSlice';
import {
  useCreateVideoSelector,
  useVideoSelector,
} from '../state/video/selectors';
import { Paths, SecondaryPaths } from '../types/common';
import { CreateLink } from './create-link';

const Wrapper = styled('div')`
  padding-bottom: 60px;
  padding-right: 20px;

  @media (max-width: 948px) {
    padding-bottom: 120px;
    padding-left: 20px;
  }
`;

const ContentWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 80px;

  @media (max-width: 989px) {
    display: flex;
    flex-direction: column;
  }
`;

const VideoWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 38px;
  flex: 1 1 auto;
  @media (min-width: 990px) {
    min-width: 470px;
    max-width: 1120px;
    width: 100%;
  }
`;

const LoadingWrapper = styled('div')`
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 54px;
  min-height: 340px;
`;

const RightSide = styled('div') <{ maxHeight: string }>`
  flex: 1 1 0;
  padding-bottom: 140px;

  @media (min-width: 990px) {
    max-height: ${(props) => props.maxHeight};
    max-width: 560px;
    min-width: 330px;
  }
`;

const ButtonsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 40px;
`;

export const Video: FC = () => {
  const { t } = useTranslation([LocaleKeys.VIDEO]);
  const dispatch = useAppDispatch();
  const id = useVideoPathId();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const rightSideRef = useRef<HTMLDivElement | null>(null);
  const { data, loading } = useVideoSelector();
  const navigate = useNavigate();

  const {
    secondStage: { form },
  } = useCreateVideoSelector();

  const { message } = App.useApp();

  useLoadVideo();

  const [isApproved, setIsApproved] = useState(false);
  const videoWrapperRef = useRef<HTMLDivElement | null>(null);
  const [rightSideMaxHeight, setRightSideMaxHeight] = useState('700px');
  const [videoWrapperWidth, setVideoWrapperWidth] = useState('1120px');
  const [rightSideWidth, setRightSideWidth] = useState('560px');

  const [descriptionEditMode, setDescriptionEditMode] = useState(false);

  const handleSwitchDescriptionMode = useCallback(() => {
    setDescriptionEditMode((prev) => {
      return !prev;
    });
  }, []);

  const handleResetDescriptionMode = useCallback(() => {
    setDescriptionEditMode(false);
  }, []);

  const handleDeleteVideo = useCallback(async () => {
    if (!id) {
      message.warning(t('video.deleting.warning'));
      return;
    }

    const loading = message.loading(t('video.deleting.loading'), 0);

    try {
      await API.videos.deleteById(id);

      loading();
      message.success(t('video.deleting.success'));
      dispatch(resetVideoListState());
      navigate(Paths.UPLOADED_VIDEOS);
    } catch (e) {
      loading();
      message.error(t('video.deleting.error'));
    }
  }, [id, t]);

  const handleChangeTitle = useCallback(async () => {
    setIsApproved((prevState) => !prevState);
    if (!id || !form.title || !form.description) {
      return;
    }

    const loading = message.loading(t('video.changing.loading'), 0);

    try {
      await API.videos.editVideo(
        {
          title: form.title,
          description: form.description,
        },
        id
      );

      handleSwitchDescriptionMode();
      loading();
      message.success(t('video.changing.success'));
      dispatch(resetVideoStateAction());
      dispatch(resetVideoListState());
      setIsApproved(false);
    } catch (e) {
      loading();
      message.error(t('video.changing.error'));
    }
  }, [id, form, t]);

  useEffect(() => {
    const updateRightSideHeight = () => {
      if (videoWrapperRef.current) {
        const videoWrapperHeight = videoWrapperRef.current.offsetHeight;
        setRightSideMaxHeight(`${videoWrapperHeight}px`);
      }
    };

    const updateVideoWrapperWidth = () => {
      if (videoWrapperRef.current) {
        const videoWrapperWidth = videoWrapperRef.current.offsetWidth;
        setVideoWrapperWidth(`${videoWrapperWidth}px`);
      }
    };

    const updateRightSideWidth = () => {
      if (rightSideRef.current) {
        const rightSideWidth = rightSideRef.current.offsetWidth;
        setRightSideWidth(`${rightSideWidth}px`);
      }
    };
    updateRightSideHeight();
    updateVideoWrapperWidth();
    updateRightSideWidth();

    window.addEventListener('resize', updateRightSideHeight);
    window.addEventListener('resize', updateRightSideWidth);
    window.addEventListener('resize', updateVideoWrapperWidth);
    return () => {
      window.removeEventListener('resize', updateRightSideHeight);
      window.removeEventListener('resize', updateRightSideWidth);
      window.removeEventListener('resize', updateVideoWrapperWidth);
    };
  }, []);

  useEffect(() => {
    if (data && descriptionEditMode) {
      dispatch(
        createVideoSetSecondFormAction({
          title: data.title,
          description: data.description,
        })
      );
    }
  }, [data, descriptionEditMode]);

  return (
    <Wrapper>
      <PageBackButton
        text={t('buttons.goBack')}
        onClick={() => navigate('/uploaded')}
      />
      {loading ? (
        <LoadingWrapper>
          <LoadingOutlined />
        </LoadingWrapper>
      ) : (
        <ContentWrapper>
          <VideoWrapper ref={videoWrapperRef}>
            <VideoPlayer
              videoSrc={data?.originUrls ?? mockVideo}
              ref={videoRef}
              preview={
                data?.videoPreview?.url
                  ? {
                    url: data?.videoPreview?.url,
                    alt: data?.title ?? t('statistics.noName'),
                  }
                  : undefined
              }
            />
          </VideoWrapper>
          <RightSide ref={rightSideRef} maxHeight={rightSideMaxHeight}>
            {descriptionEditMode ? (
              <DescriptionEdit
                isCreateMode
                onSwitchMode={handleChangeTitle}
                isApproved={descriptionEditMode}
                isHaveEditutton
              />
            ) : (
              <DescriptionPreview
                title={data?.title ?? t('statistics.noName')}
                content={
                  data?.description ?? t('statistics.link.noDescription')
                }
                onSwitchMode={handleSwitchDescriptionMode}
                maxHeight={rightSideMaxHeight}
              />
            )}
            <ButtonsWrapper>
              {descriptionEditMode ? (
                <Button size="large" onClick={handleResetDescriptionMode}>
                  {t('buttons.cancel')}
                </Button>
              ) : (
                <Popconfirm
                  title={t('video.deleting.title')}
                  description={t('video.deleting.description')}
                  onConfirm={handleDeleteVideo}
                  okText={t('buttons.deleting.success')}
                  cancelText={t('buttons.cancel')}
                  placement="bottom"
                  okButtonProps={{
                    danger: true,
                    type: 'primary',
                  }}
                >
                  <Button
                    type="primary"
                    size="large"
                    danger
                    className="delete-button"
                  >
                    {t('buttons.delete')}
                  </Button>
                </Popconfirm>
              )}
            </ButtonsWrapper>
          </RightSide>
        </ContentWrapper>
      )}
      <Routes>
        <Route path={`/`} element={<LinksList width={rightSideWidth} />} />
        <Route
          path={`${SecondaryPaths.CREATE_LINK}`}
          element={<CreateLink width={videoWrapperWidth} videoId={id ?? ''} />}
        />
        <Route
          path={`${SecondaryPaths.EDIT_LINK}/:${VIDEO_LINK_PARAM_NAME}`}
          element={
            <CreateLink
              width={videoWrapperWidth}
              isEditMode
              videoId={id ?? ''}
            />
          }
        />
        <Route
          path={`${SecondaryPaths.STATISTICS}/:${VIDEO_LINK_PARAM_NAME}`}
          element={
            <CreateLink
              width={videoWrapperWidth}
              isEditMode
              videoId={id ?? ''}
            />
          }
        />
      </Routes>
    </Wrapper>
  );
};
