import styled from '@emotion/styled';
import { FC, memo, useCallback, useEffect, useState } from 'react';

import { boolToText, textToBool } from '../../utils/common';

const Wrapper = styled('div')`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const SelectItem = styled('div')<{ active: 'true' | 'false' }>`
  border-radius: 8px;
  background-color: ${({ theme, active }) =>
    textToBool(active) ? theme.COLORS.ACCENT._600 : theme.COLORS.BLACK._300};
  cursor: pointer;
  transition: background-color 0.3s ease-out;
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  padding: 16px 12px;
  color: ${({ theme }) => theme.COLORS.WHITE._300};
  text-align: center;

  :hover {
    background-color: ${({ theme, active }) =>
      textToBool(active) ? theme.COLORS.ACCENT._600 : theme.COLORS.BLACK._400};
  }
`;

export type TOption = {
  label: string;
  value: string;
};

type TBlockSelectProps<T extends string | number> = {
  options: TOption[];
  value: T;
  setValue: (value: T) => void;
};

type TBlockItemProps = {
  value: string;
  label: string;
  current: boolean;
  handleChange: (value: string) => void;
};

const BlockItem: FC<TBlockItemProps> = memo(
  ({ value, label, handleChange, current }) => {
    const onChange = () => {
      handleChange(value);
    };

    return (
      <SelectItem onClick={onChange} active={boolToText(current)}>
        <span>{label}</span>
      </SelectItem>
    );
  }
);

export const BlockSelectable: FC<TBlockSelectProps<string>> = ({
  options,
  value,
  setValue,
}) => {
  const [valueState, setValueState] = useState(value);

  useEffect(() => {
    setValueState(value);
  }, [value]);

  const handleChange = useCallback(
    (value: string | number) => {
      setValueState(value as any);
      setValue(value as any);
    },
    [setValue]
  );

  return (
    <Wrapper>
      {options.map((opt) => (
        <BlockItem
          key={opt.value}
          value={opt.value}
          label={opt.label}
          current={opt.value === (valueState as any)}
          handleChange={handleChange}
        />
      ))}
    </Wrapper>
  );
};
