import { LoadingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { FC, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseBlock } from '../components/common/blocks';
import { PageBackButton } from '../components/common/page-back-button';
import {
  BaseText,
  BaseTitle,
  MainBlock,
  MainBlockTitle,
} from '../components/video-page/about-video/components';
import { TimeLineControl } from '../components/video-page/time-line-control';
import { VideoPlayer } from '../components/video-player';
import { useLoadVideoWithStat } from '../hooks/use-load-video-with-stat';
import { LocaleKeys } from '../locale';
import mockVideo from '../mocks/mock-video.mp4';
import { useLocaleSelector } from '../state/locale/selector';
import {
  useLinkStatSelector,
  useVideoSelector,
} from '../state/video/selectors';
import { pluralize } from '../utils/text-formatters';
import { useSearchParams } from '../utils/useSearchParams';

const Wrapper = styled('div')`
  padding-bottom: 60px;
  padding-right: 40px;

  @media (max-width: 948px) {
    padding-right: 40px;
    padding-left: 40px;
    padding-bottom: 100px;
  }
`;

export const DataWrapper = styled('div')`
  display: grid;
  grid-template-columns: minmax(140px, 1fr) minmax(140px, 1fr) minmax(
      140px,
      1fr
    );
  grid-template-rows: repeat(auto-fill, minmax(140px, 1fr));
  row-gap: 20px;
  column-gap: 20px;
  height: fit-content;

  @media (max-width: 1420px) {
    order: 2;
  }

  @media (max-width: 540px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
`;

const ContentWrapper = styled('div')`
  display: grid;
  grid-template-columns: 5fr 2fr;
  column-gap: 60px;
  margin-top: 80px;

  @media (max-width: 1420px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`;

const VideoWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 38px;
`;

const LoadingWrapper = styled('div')`
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 54px;
  min-height: 340px;
`;

const StatsTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: white;
  gap: 30px;
  margin-top: -12px;

  > div {
    height: 4px;
  }
  @media (max-width: 1420px) {
    order: 1;
    display: none;
  }
`;

export const LinkStatistic: FC = () => {
  const curLanguage = useLocaleSelector();
  const { t } = useTranslation([LocaleKeys.VIDEO]);

  const videoRef = useRef<HTMLVideoElement | null>(null);

  const { loading, data } = useVideoSelector();
  const {
    data: linkStat,
    loading: linkStatLoading,
    chunks,
  } = useLinkStatSelector();

  const {
    query: { userId },
  } = useSearchParams();

  useLoadVideoWithStat();

  const getLinkTitle = useMemo(() => {
    if (!loading && !linkStatLoading) {
      if (userId)
        return `${linkStat?.fullname || t('statistics.link.button.noName')}`;
      return `${t('statistics.link.button.title')} ${linkStat?.name || t('statistics.link.button.noName')}`;
    } else return t('statistics.link.button.loading');
  }, [loading, linkStatLoading, userId]);

  return (
    <Wrapper>
      <PageBackButton text={getLinkTitle} />
      {loading || linkStatLoading ? (
        <LoadingWrapper>
          <LoadingOutlined />
        </LoadingWrapper>
      ) : (
        <ContentWrapper>
          <VideoWrapper>
            <VideoPlayer
              videoSrc={data?.originUrls ?? mockVideo}
              ref={videoRef}
              LineVisible={false}
              preview={
                data?.videoPreview?.url
                  ? {
                    url: data?.videoPreview?.url,
                    alt: data?.title ?? t('statistics.link.button.noName'),
                  }
                  : undefined
              }
            />
          </VideoWrapper>
          <DataWrapper>
            <MainBlockTitle
              style={{
                position: 'absolute',
              }}
            >
              {userId ? linkStat?.fullname : t('statistics.link.title')}
            </MainBlockTitle>
            <MainBlock
              style={{
                marginTop: 50,
              }}
            >
              <BaseTitle>{linkStat?.videoViews || 0}</BaseTitle>
              <BaseText>
                {pluralize(linkStat?.views ?? 0, curLanguage.lang, [
                  t('videos.viewing.one'),
                  t('videos.viewing.many'),
                  t('videos.viewing.few'),
                ])}
              </BaseText>
            </MainBlock>
            <MainBlock>
              <BaseTitle>
                {userId ? linkStat?.viewsCount : linkStat?.views || 0}{' '}
                {pluralize(linkStat?.views ?? 0, curLanguage.lang, [
                  t('videos.viewing.one'),
                  t('videos.viewing.many'),
                  t('videos.viewing.few'),
                ])}
              </BaseTitle>
              <BaseText>{t('statistics.link.via')}</BaseText>
            </MainBlock>
            <BaseBlock>
              <BaseTitle>{linkStat?.attentiveness || 0}%</BaseTitle>
              <BaseText>{t('statistics.link.attention')}</BaseText>
            </BaseBlock>
            <BaseBlock>
              <BaseTitle>{linkStat?.engagement || 0}%</BaseTitle>
              <BaseText>{t('statistics.link.involvement')}</BaseText>
            </BaseBlock>
            <BaseBlock>
              <BaseTitle>{linkStat?.emotionality || 0}%</BaseTitle>
              <BaseText>{t('statistics.link.happiness')}</BaseText>
            </BaseBlock>
          </DataWrapper>
          <div
            style={{
              marginTop: 40,
            }}
          >
            <TimeLineControl
              ref={videoRef}
              showByStats
              chunksToVisualize={chunks}
            />
          </div>
          <div
            style={{
              marginTop: 40,
            }}
          >
            <StatsTextWrapper>
              <div>{t('statistics.link.attention')}</div>
              <div>{t('statistics.link.involvement')}</div>
              <div>{t('statistics.link.happiness')}</div>
              <div>{t('statistics.link.fatigue')}</div>
              <div>{t('statistics.link.distraction')}</div>
            </StatsTextWrapper>
          </div>
        </ContentWrapper>
      )}
    </Wrapper>
  );
};
