import App from 'antd/lib/app';
import { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { API } from '../api';
import { useAppDispatch } from '../state';
import {
  setVideoByIdAction,
  setVideoByIdError,
  setVideoByIdLoading,
} from '../state/video/byIdSlice';
import { setLinkStatAction } from '../state/video/linkStat';
import { Paths } from '../types/common';
import { useSearchParams } from '../utils/useSearchParams';
import { useVideoLinkPathId, useVideoPathId } from './use-video-path-id';

export const useLoadVideoWithStat: () => [
  () => Promise<void>,
  string | undefined,
  string | undefined,
] = () => {
  const dispatch = useAppDispatch();
  const id = useVideoPathId();
  const linkId = useVideoLinkPathId();
  const loadingRef = useRef(false);
  const navigate = useNavigate();
  const { message } = App.useApp();
  const {
    query: { userId },
  } = useSearchParams();

  const handleLoadVideo = useCallback(async () => {
    if (id && linkId) {
      loadingRef.current = true;
      dispatch(setVideoByIdLoading(true));
      try {
        const dataPromise: ReturnType<typeof API.videos.getById> = new Promise(
          (res) => {
            API.videos.getById(id).then((r) => {
              res(r);
            });
          }
        );
        const linkStatPromise: ReturnType<typeof API.videos.getLinkStat> =
          new Promise((res) => {
            API.videos.getLinkStat(linkId, userId as string).then((r) => {
              res(r);
            });
          });

        const linkChunksStatPromise: ReturnType<
          typeof API.videos.getLinChunkskStat
        > = new Promise((res) => {
          API.videos.getLinChunkskStat(linkId, userId as string).then((r) => {
            res(r);
          });
        });

        const [response, linkStatResponse, linkChunksStat] = await Promise.all([
          dataPromise,
          linkStatPromise,
          linkChunksStatPromise,
        ]);
        dispatch(
          setVideoByIdAction({
            videoData: response.data,
          })
        );
        dispatch(
          setLinkStatAction({
            stat: linkStatResponse.data,
            chunks: linkChunksStat.data,
          })
        );
        loadingRef.current = false;
      } catch (e) {
        dispatch(setVideoByIdError(true));
        dispatch(setVideoByIdLoading(false));
        loadingRef.current = false;
        message.error('Видео не найдено');
        navigate(Paths.UPLOADED_VIDEOS);
      }
    }
  }, [id, userId, linkId, loadingRef, dispatch]);

  useEffect(() => {
    if (!loadingRef.current) {
      handleLoadVideo();
    }
  }, [loadingRef, handleLoadVideo]);

  return [handleLoadVideo, id, linkId];
};
