import styled from '@emotion/styled';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LocaleKeys } from '../../locale';
import { CommonSelect } from './common-select';
import { SlideInput } from './slide-input';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  .container-input {
    display: flex;
    gap: 10px;
    padding: 6px;
    border: 1px solid ${({ theme }) => theme.COLORS.BLACK._400};
    border-radius: 8px;
    @media (max-width: 989px) {
      flex-direction: column;
    }
  }
  .container-input__block {
    min-width: 414px;
    max-width: 414px;
  }
  @media (max-width: 1230px) {
    .container-input__block {
      min-width: 304px;
      max-width: 304px;
    }
  }
  @media (max-width: 989px) {
    .container-input__block {
      max-width: 100%;
    }
  }
`;

type TProps = {
  onBaseSettingsChange?: (key: string, value: any) => void;
  settings: Record<string, any>;
};

export const BaseSettings: FC<TProps> = ({
  onBaseSettingsChange,
  settings,
}) => {
  const { t } = useTranslation([LocaleKeys.VIDEO]);
  const [guarantee, setGuarantee] = useState(false);
  const [attention, setAttention] = useState(false);
  const [attentionRate, setAttentionRate] = useState(25);
  const [interest, setInterest] = useState(false);
  const [interestRate, setInterestRate] = useState(25);
  const [voice, setVoice] = useState(false);
  const [voiceRate, setVoiceRate] = useState(25);
  const [, setSpeedControl] = useState(false);
  const [, setTimeControl] = useState(false);
  const [distanceControl, setDistanceControl] = useState(false);

  useEffect(() => {
    if (settings) {
      setGuarantee(settings.viewingGuarantee);
      setAttention(settings.increaseAttention);
      setAttentionRate(settings.increaseAttentionValue);
      setInterest(settings.minimumAttentionLimit);
      setInterestRate(settings.minimumAttentionLimitValue);
      setVoice(settings.volumeLimit);
      setVoiceRate(settings.volumeLimitValue);
      setSpeedControl(settings.videoSpeedControl ?? false);
      setTimeControl(settings.rewindOption ?? false);
      setDistanceControl(settings.distanceControl);
    }
  }, [settings]);

  return (
    <Wrapper>
      {/* <SlideInput */}
      {/*   label={t('settings.attention.value')} */}
      {/*   min={0} */}
      {/*   max={100} */}
      {/*   value={attentionRate} */}
      {/*   onChange={(value) => { */}
      {/*     if (onBaseSettingsChange) { */}
      {/*       onBaseSettingsChange('increaseAttentionValue', value); */}
      {/*     } */}
      {/*   }} */}
      {/* /> */}
      <CommonSelect
        value={guarantee}
        setValue={(value) => {
          if (onBaseSettingsChange) {
            onBaseSettingsChange('viewingGuarantee', value);
          }
        }}
        label={t('settings.view')}
      />
      <div />
      <div className="container-input">
        <CommonSelect
          className="container-input__block"
          value={attention}
          setValue={(value) => {
            if (onBaseSettingsChange) {
              onBaseSettingsChange('increaseAttention', value);
            }
          }}
          label={t('settings.attention.title')}
        />
        <SlideInput
          label={t('settings.attention.value')}
          min={0}
          max={100}
          value={attentionRate}
          onChange={(value) => {
            if (onBaseSettingsChange) {
              onBaseSettingsChange('increaseAttentionValue', value);
            }
          }}
        />
      </div>
      <div className="container-input">
        <CommonSelect
          className="container-input__block"
          value={interest}
          label={t('settings.attention.limit.title')}
          setValue={(value) => {
            if (onBaseSettingsChange) {
              onBaseSettingsChange('minimumAttentionLimit', value);
            }
          }}
        />
        <SlideInput
          label={t('settings.attention.limit.value')}
          min={0}
          max={100}
          value={interestRate}
          onChange={(value) => {
            if (onBaseSettingsChange) {
              onBaseSettingsChange('minimumAttentionLimitValue', value);
            }
          }}
        />
      </div>
      <div className="container-input">
        <CommonSelect
          className="container-input__block"
          value={voice}
          setValue={(value) => {
            if (onBaseSettingsChange) {
              onBaseSettingsChange('volumeLimit', value);
            }
          }}
          label={t('settings.volume.title')}
        />
        <SlideInput
          label={t('settings.volume.value')}
          min={0}
          max={100}
          value={voiceRate}
          onChange={(value) => {
            if (onBaseSettingsChange) {
              onBaseSettingsChange('volumeLimitValue', value);
            }
          }}
        />
      </div>
      {/*<CommonSelect*/}
      {/*  value={speedControl}*/}
      {/*  setValue={(value) => {*/}
      {/*    if (onBaseSettingsChange) {*/}
      {/*      onBaseSettingsChange('videoSpeedControl', value);*/}
      {/*    }*/}
      {/*  }}*/}
      {/*  label="Контроль скорости видео"*/}
      {/*/>*/}
      {/*<div />*/}
      {/*<CommonSelect*/}
      {/*  value={timeControl}*/}
      {/*  setValue={(value) => {*/}
      {/*    if (onBaseSettingsChange) {*/}
      {/*      onBaseSettingsChange('rewindOption', value);*/}
      {/*    }*/}
      {/*  }}*/}
      {/*  label="Возможность перемотки"*/}
      {/*/>*/}
      {/*<div />*/}
      <CommonSelect
        value={distanceControl}
        setValue={(value) => {
          if (onBaseSettingsChange) {
            onBaseSettingsChange('distanceControl', value);
          }
        }}
        label={t('settings.distance')}
      />
    </Wrapper>
  );
};
