import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Settings } from '../components/video-page/settings';

export const CreateLink: FC<{
  videoId: string;
  isEditMode?: boolean;
  width: string;
}> = ({ videoId, isEditMode, width }) => {
  const { linkId } = useParams();

  if (!linkId && isEditMode) {
    return null;
  }

  return (
    <div>
      <Settings
        width={width}
        videoId={videoId}
        viewMode="create"
        linkToEdit={linkId}
      />
    </div>
  );
};
