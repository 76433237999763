import { FC, HTMLAttributes } from 'react';

export const VideoStop: FC<HTMLAttributes<HTMLOrSVGElement>> = (props) => {
  return (
    <svg
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.5 0C5.15685 0 6.5 1.34315 6.5 3V19C6.5 20.6569 5.15685 22 3.5 22C1.84315 22 0.5 20.6569 0.5 19V3C0.5 1.34315 1.84315 0 3.5 0ZM14.5 0C16.1569 0 17.5 1.34315 17.5 3V19C17.5 20.6569 16.1569 22 14.5 22C12.8431 22 11.5 20.6569 11.5 19V3C11.5 1.34315 12.8431 0 14.5 0Z"
        fill="#1E4141"
      />
    </svg>
  );
};
