import { FC, HTMLAttributes } from 'react';

export const VideoPlayFilled: FC<HTMLAttributes<HTMLOrSVGElement>> = (
  props
) => {
  return (
    <svg
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.8125 8.89844C18.0234 9.64062 18.0234 11.3984 16.8125 12.1406L3.0625 20.2656C1.8125 21.0078 0.25 20.1094 0.25 18.625V2.375C0.25 0.773438 1.92969 0.109375 3.0625 0.773438L16.8125 8.89844Z"
        fill="#1E4141"
      />
    </svg>
  );
};
