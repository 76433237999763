import { ThemeProvider } from '@emotion/react';
import App from 'antd/lib/app';
import ConfigProvider from 'antd/lib/config-provider';
import { FC, PropsWithChildren, useLayoutEffect } from 'react';

import { DarkTheme } from '../styles/theme';

type TThemeObjectType = {
  [key: string]: string | TThemeObjectType;
};

function objToRootStyleProps(
  root: HTMLElement,
  obj: TThemeObjectType,
  parentKey?: string
) {
  (Object.keys(obj) as (keyof TThemeObjectType)[]).forEach((key) => {
    if (typeof obj[key] === 'object') {
      objToRootStyleProps(
        root,
        obj[key] as TThemeObjectType,
        parentKey ? parentKey + `-${key}` : (key as string)
      );
    } else {
      let valueKey = key as string;

      if (valueKey[0] === '_') {
        valueKey = valueKey.substring(1, valueKey.length);
      }

      root.style.setProperty(
        `${parentKey + '-' ?? '--'}${valueKey}`,
        obj[key] as string
      );
    }
  });
}

export const ThemeService: FC<PropsWithChildren> = ({ children }) => {
  useLayoutEffect(() => {
    const root = document.documentElement;

    objToRootStyleProps(root, DarkTheme, '--theme');
  }, []);

  return (
    <ThemeProvider theme={DarkTheme}>
      <ConfigProvider
        locale={{
          locale: 'ru',
        }}
        theme={{
          token: {
            colorPrimary: DarkTheme.COLORS.ACCENT._200,
            colorError: DarkTheme.COLORS.DANGER,
            colorTextBase: DarkTheme.COLORS.WHITE._100,
            colorBgBase: DarkTheme.COLORS.BLACK._200,
            borderRadius: 4,
          },
          components: {
            Modal: {
              contentBg: DarkTheme.COLORS.BLACK._200,
            },
            Slider: {
              handleSize: 12,
              handleActiveOutlineColor: 'rgba(0, 0, 0, 0)',
              trackBg: '#39D5C9',
            },
          },
        }}
      >
        <App>{children}</App>
      </ConfigProvider>
    </ThemeProvider>
  );
};
