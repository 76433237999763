import styled from '@emotion/styled';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import { boolToText, textToBool } from '../../utils/common';
import { VideoPlay } from '../icons/video-play';
import { TimeLineControl } from '../video-page/time-line-control';
import { Controls } from './controls';

type TProps = {
  videoSrc: string;
  className?: string;
  preview?: {
    url: string;
    alt?: string;
  };
  LineVisible?: boolean;
};

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Wrapper = styled('div')<{ controlsVisible: 'true' | 'false' }>`
  width: 100%;
  height: 0;
  padding-top: 56%;
  position: relative;
  overflow: hidden;
  border-radius: 20px;

  video {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: ${({ controlsVisible }) =>
      textToBool(controlsVisible) ? 'pointer' : 'none'};
  }
`;

const SetControls = styled('div')`
  display: flex;
  align-items: start;
  gap: 20px;

  & > div:last-child {
    flex-grow: 1;
    padding-top: 18px;
  }
`;
const PreviewWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 110%;
    height: 110%;
    object-fit: cover;
  }
`;
const PlayButton = styled('div')`
  display: flex;
  width: 100px;
  height: 100px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  pointer-events: none;
`;

export const VideoPlayer = forwardRef<HTMLVideoElement, TProps>(
  ({ videoSrc, className, preview, LineVisible = true }, ref) => {
    const wrapperRef = useRef<HTMLDivElement | null>(null);

    const [isZeroTiming, setZeroTiming] = useState(true);

    const [play, setPlay] = useState(false);
    const [controlsVisible, setControlsVisible] = useState(true);

    const visibleTimeoutRef = useRef<NodeJS.Timeout>();

    const togglePlay = useCallback(() => {
      if (play) {
        setPlay(false);

        if (ref && 'current' in ref) {
          void ref.current?.pause();
        }
      } else {
        if (ref && 'current' in ref) {
          void ref.current?.play();
        }
      }
    }, [play]);

    const handleMouseOver = useCallback(() => {
      if (!controlsVisible) {
        setControlsVisible(true);

        if (visibleTimeoutRef.current) {
          clearTimeout(visibleTimeoutRef.current);
        }
      }
    }, [controlsVisible]);

    useEffect(() => {
      if (controlsVisible) {
        if (visibleTimeoutRef.current) {
          clearTimeout(visibleTimeoutRef.current);
        }

        visibleTimeoutRef.current = setTimeout(() => {
          setControlsVisible(false);
        }, 3000);
      }
    }, [controlsVisible]);

    useEffect(() => {
      if (ref && 'current' in ref && ref.current) {
        ref.current.onended = () => {
          setPlay(false);
          setZeroTiming(true);
        };

        ref.current.onpause = () => {
          setPlay(false);
        };

        ref.current.onplay = () => {
          setPlay(true);
          setZeroTiming(false);
        };

        ref.current?.addEventListener('timeupdate', () => {
          setZeroTiming(false);
        });
      }
    }, []);

    return (
      <MainWrapper>
        <Wrapper
          className={className}
          ref={wrapperRef}
          onMouseMove={handleMouseOver}
          controlsVisible={boolToText(controlsVisible)}
        >
          <video
            src={videoSrc}
            controls={false}
            ref={ref}
            onClick={togglePlay}
            crossOrigin="anonymous"
          />
          {preview && isZeroTiming && (
            <PreviewWrapper>
              <img src={preview.url} alt={preview.alt} />
            </PreviewWrapper>
          )}
          {!play && (
            <PlayButton>
              <VideoPlay />
            </PlayButton>
          )}
        </Wrapper>
        <SetControls>
          <Controls
            play={play}
            togglePlay={togglePlay}
            ref={ref}
            controlsVisible={controlsVisible || !play}
          />
          {LineVisible && <TimeLineControl ref={ref} />}
        </SetControls>
      </MainWrapper>
    );
  }
);
