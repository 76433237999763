import React, { JSX } from 'react';

import { cellStyle, headerStyle, stickyColumnStyle } from './styles';
import { TSortValue, TableColumn, TableProps } from './types';

interface TableHeaderProps<T extends object> {
  columns: TableColumn<T>[];
  sortedData: TableProps<T>['sortedData'];
  setSortedData: (newValue: Partial<Record<keyof T, TSortValue>>) => void;
  isLoading: boolean | undefined;
}

export const TableHeader: <T extends object>(
  props: TableHeaderProps<T>
) => JSX.Element = ({ columns, sortedData, setSortedData, isLoading }) => {
  return (
    <thead className={headerStyle.name}>
      <tr>
        {columns.map((column, i) => (
          <th
            key={column.key as string}
            className={cellStyle.name}
            css={i === 0 && !isLoading && stickyColumnStyle}
          >
            {column.columnComponent ? (
              <column.columnComponent
                label={column.label}
                sorted={sortedData[column.key]}
                setSorted={setSortedData}
                columnKey={column.key}
                tooltip={column.tooltip}
                sortable={column.sortable}
              />
            ) : (
              column.label
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};
