import React, { ReactNode, memo, useEffect, useRef, useState } from 'react';

import { rowStyle, stickyColumnStyle } from './styles';
import { TableCell } from './table-cell';
import { TableColumn, TableRowData } from './types';

interface TableRowProps<T extends object> {
  row: TableRowData<T>;
  columns: TableColumn<T>[];
  depth: number; // Добавляем глубину для отступов
  expanded?: boolean;
  childIndex?: number;
  loading?: boolean | undefined;
}

export const TableRow: <T extends object>(
  props: TableRowProps<T>
) => ReactNode = memo(
  ({ row, columns, depth, expanded, childIndex, loading }) => {
    const [isOpen, setIsOpen] = useState(expanded ?? false);
    const toggleOpen = () => setIsOpen(!isOpen);

    const rowRef = useRef<HTMLTableRowElement | null>(null);

    const [hovered, setHovered] = useState(false);

    useEffect(() => {
      const listener = () => {
        setHovered(true);
      };

      const listenerOut = () => {
        setHovered(false);
      };

      if (rowRef.current) {
        rowRef.current?.addEventListener('mouseenter', listener);
        rowRef.current?.addEventListener('mouseleave', listenerOut);
      }

      return () => {
        if (rowRef.current) {
          rowRef.current?.removeEventListener('mouseenter', listener);
          rowRef.current?.removeEventListener('mouseleave', listenerOut);
        }
      };
    }, []);

    return (
      <>
        <tr css={rowStyle(isOpen)} ref={rowRef}>
          {columns.map((column, i) => (
            <TableCell
              key={column.key as string}
              content={
                column.renderFunc
                  ? column.renderFunc(
                      row.data[column.key],
                      row.data,
                      isOpen,
                      (row.children?.length ?? 0) > 0,
                      childIndex ?? 0,
                      hovered
                    )
                  : row.data[column.key]
              }
              canBeExpanded={(row.children?.length ?? 0) > 0}
              onClick={(row.children?.length ?? 0) > 0 ? toggleOpen : undefined}
              isSticky={i === 0}
              isLoading={loading}
              data-is-open={isOpen}
            />
          ))}
        </tr>
        {isOpen &&
          row.children?.map((child) => (
            <TableRow
              key={child.id}
              row={child}
              columns={columns}
              depth={depth + 1}
              expanded={true}
              childIndex={(childIndex ?? 0) + 1}
            />
          ))}
      </>
    );
  }
);
