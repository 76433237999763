import styled from '@emotion/styled';
import { Popconfirm, message } from 'antd';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { API } from '../../api';
import editIcon from '../../assets/edit-icon.svg';
import emailIcon from '../../assets/email-icon.svg';
import exitIcon from '../../assets/exit-icon.svg';
import passwordIcon from '../../assets/password-change.svg';
import planetIcon from '../../assets/planet-icon.svg';
import trashIcon from '../../assets/trash-icon.svg';
import { APP_LANGUAGES } from '../../constants/app-lang';
import { LocaleKeys } from '../../locale';
import { AccountEmailModal } from '../../modals/video/account-email-modal';
import { AccountNameModal } from '../../modals/video/account-name-modal';
import { AccountPasswordModal } from '../../modals/video/account-password-modal';
import { ChangeLanguageModal } from '../../modals/video/change-language-modal';
import { useAppDispatch } from '../../state';
import { logoutAction } from '../../state/auth/slice';
import { useLocaleSelector } from '../../state/locale/selector';

const Wrapper = styled('div')`
  margin-top: 32px;
  width: 400px;
  
  @media (max-width: 468px) {
    width: auto;
  }
`;

const ItemWrapper = styled('div')`
  border-radius: 20px;
  background-color: ${({ theme }) => theme.COLORS.BLACK._400};
  cursor: pointer;
  transition: background-color 0.3s ease-out;
  display: flex;
  align-items: center;
  padding: 20px;

  &:hover {
    background-color: ${({ theme }) => theme.COLORS.BLACK._300};
  }

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  &:last-of-type {
    margin-top: 34px;
    color: ${({ theme }) => theme.COLORS.DANGER};
  }
`;

const IcoWrapper = styled('div')`
  width: 16px;
  height: 16px;
  margin-right: 16px;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;

const Label = styled('div')`
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
`;

const Value = styled('div')`
  align-self: flex-end;
  flex: 1;
  text-align: right;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  color: ${({ theme }) => theme.COLORS.WHITE._600};
`;

const MenuItem: FC<{
  label: string;
  iconUrl: string;
  currentValue?: string;
  onClick?: () => void;
  href?: string;
}> = ({ iconUrl, label, currentValue, onClick }) => {
  return (
    <ItemWrapper onClick={onClick}>
      <IcoWrapper>
        <img src={iconUrl} alt="" />
      </IcoWrapper>
      <Label>{label}</Label>
      {currentValue && <Value>{currentValue}</Value>}
    </ItemWrapper>
  );
};

type TMenuItem = {
  label: string;
  iconUrl: string;
  currentValue?: string;
  onClick?: () => void;
  href?: string;
  id: string;
};

export const UserMenu: FC = () => {
  const { t } = useTranslation([LocaleKeys.ACCOUNT]);
  const dispatch = useAppDispatch();
  const currentLanguage = useLocaleSelector();

  const [nameModal, setNameModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [languageModal, setLanguageModal] = useState(false);

  const logout = () => {
    dispatch(logoutAction());
  };

  const openNameChangeModal = () => {
    setNameModal(true);
  };

  const openPasswordChangeModal = () => {
    setPasswordModal(true);
  };

  const openEmailChangeModal = () => {
    setEmailModal(true);
  };

  const openLanguageModal = () => {
    setLanguageModal(true);
  };

  const deleteAccount = async () => {
    const loading = message.loading(t('menu.deleting.process'), 0);

    try {
      await API.me.deleteAccount();

      loading();
      message.success(t('menu.deleting.success'));
      logout();
    } catch (e) {
      loading();
      message.error(t('menu.deleting.error'));
    }
  };

  const userMenuList: {
    label: string;
    iconUrl: string;
    currentValue?: string;
    onClick?: () => void;
    href?: string;
    id: string;
  }[] = useMemo(
    () =>
      (
        [
          // {
          //   label: 'Изменить фотографию',
          //   iconUrl: photoIcon,
          //   id: 'avatar',
          // },
          {
            label: t('menu.name'),
            iconUrl: editIcon,
            onClick: openNameChangeModal,
            id: 'name',
          },
          {
            label: t('menu.email'),
            iconUrl: emailIcon,
            onClick: openEmailChangeModal,
            id: 'email',
          },
          {
            label: t('menu.password'),
            iconUrl: passwordIcon,
            onClick: openPasswordChangeModal,
            id: 'password',
          },
          APP_LANGUAGES.length > 1 && {
            label: t('menu.language'),
            iconUrl: planetIcon,
            onClick: openLanguageModal,
            currentValue: APP_LANGUAGES.find(
              (el) => el.value === currentLanguage.lang
            )?.text,
            id: 'lang',
          },
          {
            label: t('menu.logout'),
            iconUrl: exitIcon,
            onClick: logout,
            id: 'logout',
          },
          {
            label: t('menu.delete'),
            iconUrl: trashIcon,
            id: 'delete',
            onClick: deleteAccount,
          },
        ] as (TMenuItem | false)[]
      ).filter(Boolean) as TMenuItem[],
    [currentLanguage, t]
  );

  return (
    <Wrapper>
      {userMenuList.map((el) =>
        el.id === 'delete' ? (
          <Popconfirm
            title={t('menu.deleting.title')}
            description={t('menu.deleting.description')}
            onConfirm={el.onClick}
            key={el.label}
            okText={t('buttons.confirm')}
            cancelText={t('buttons.cancel')}
            okType="danger"
          >
            <MenuItem {...el} onClick={undefined} />
          </Popconfirm>
        ) : (
          <MenuItem key={el.label} {...el} />
        )
      )}
      <AccountNameModal open={nameModal} setOpen={setNameModal} />
      <AccountPasswordModal open={passwordModal} setOpen={setPasswordModal} />
      <AccountEmailModal open={emailModal} setOpen={setEmailModal} />
      <ChangeLanguageModal open={languageModal} setOpen={setLanguageModal} />
    </Wrapper>
  );
};
