import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TLinkChunkStat, TLinkStat } from '../../types/responses/video';

type TState = {
  loading: boolean;
  loaded: boolean;
  errorOnLoad: boolean;
  data: TLinkStat | undefined;
  chunks: TLinkChunkStat[];
};

const initialState: TState = {
  data: undefined,
  loaded: false,
  errorOnLoad: false,
  loading: false,
  chunks: [],
};

const linkStatSlice = createSlice({
  name: 'video/link/stat/by-id',
  initialState: initialState as TState,
  reducers: {
    setLinkStatLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLinkStatError: (state, action: PayloadAction<boolean>) => {
      state.errorOnLoad = action.payload;
    },
    setLinkStatAction: (
      state,
      action: PayloadAction<{ stat: TLinkStat; chunks: TLinkChunkStat[] }>
    ) => {
      state.data = action.payload.stat;
      state.chunks = action.payload.chunks;
      state.loaded = true;
      state.loading = false;
    },
    resetLinkStatStateAction: (state) => {
      state.data = undefined;
      state.loaded = false;
      state.errorOnLoad = false;
      state.loading = false;
    },
  },
});

export const {
  setLinkStatLoading,
  setLinkStatError,
  setLinkStatAction,
  resetLinkStatStateAction,
} = linkStatSlice.actions;

export const linkStatReducer = linkStatSlice.reducer;
