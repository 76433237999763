import styled from '@emotion/styled';
import Slider from 'antd/lib/slider';
import { FC } from 'react';

import { FormBlock } from '../common/form-block';

const Wrapper = styled(FormBlock)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  text-align: left;

  @media (max-width: 539px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 64px;
    gap: 4px;
  }

  @media (max-width: 384px) {
    height: 80px;
  }
`;

const Name = styled('span')`
  display: flex;
  max-width: 190px;
  min-width: 190px;
  @media (max-width: 539px) {
    max-width: 300px;
  }
`;

const SlideWrapper = styled('div')`
  max-width: 180px;
  min-width: 52px;
  width: 100%;
  @media (max-width: 539px) {
    max-width: 80%;
  }
`;
const MarkWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
  @media (max-width: 539px) {
    justify-content: space-between;
  }
`;
const Mark = styled('div')`
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
type TProps = {
  label: string;
  min?: number;
  max?: number;
  onChange?: (value: number) => void;
  value?: number;
};

export const SlideInput: FC<TProps> = ({
  label,
  min,
  max,
  value,
  onChange,
}) => {
  const marks: Record<number, string> =
    min !== undefined && max !== undefined
      ? {
          [min]: min.toString(),
          [max]: max.toString(),
        }
      : {};

  return (
    <Wrapper>
      <Name>{label}</Name>
      <MarkWrapper>
        <SlideWrapper>
          <Slider value={value} onChange={onChange} />
        </SlideWrapper>
        <Mark>{value || 0}%</Mark>
      </MarkWrapper>
    </Wrapper>
  );
};
