import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconBaseProps } from '../../types/common';

const RewriteIcon: FC<TIconBaseProps> = ({ theme }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.07856 2.91717L13.0828 6.92144L4.38605 15.6182L0.81975 15.9936C0.3505 16.0562 -0.056184 15.6495 0.00638272 15.1803L0.381783 11.6139L9.07856 2.91717ZM15.5542 2.32279C16.1486 2.88589 16.1486 3.85567 15.5542 4.45006L13.8023 6.20193L9.79807 2.19766L11.5499 0.445788C12.1443 -0.148596 13.1141 -0.148596 13.6772 0.445788L15.5542 2.32279Z"
        fill={theme.COLORS.WHITE._100}
      />
    </svg>
  );
};

export default withTheme(RewriteIcon);
