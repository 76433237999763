import styled from '@emotion/styled';
import { FC, useMemo, useRef } from 'react';

import { PageBackButton } from '../components/common/page-back-button';
import { AboutVideoEdit } from '../components/video-page/about-video';
import { Stats } from '../components/video-page/stats';
import { StatsDescription } from '../components/video-page/stats-description';
import { TimeLineControl } from '../components/video-page/time-line-control';
import { VideoPlayer } from '../components/video-player';
import { useVideoPathId } from '../hooks/use-video-path-id';
import mockVideo from '../mocks/mock-video.mp4';
import { mockVideoList } from '../mocks/uploaded';

export const VideoEditVideoWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 38px;
`;

export const VideoEditContentWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  column-gap: 60px;
  margin-top: 80px;
`;

export const VideoEdit: FC = () => {
  const id = useVideoPathId();
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const fondedVideo = useMemo(() => {
    return mockVideoList.find((el) => el.id === id) ?? mockVideoList[0];
  }, [id]);

  return (
    <div>
      <PageBackButton text={fondedVideo ? fondedVideo.name : 'Видео'} />
      <VideoEditContentWrapper>
        <Stats />
        <VideoEditVideoWrapper>
          <VideoPlayer
            videoSrc={mockVideo}
            ref={videoRef}
            LineVisible={false}
            preview={
              fondedVideo
                ? {
                    url: fondedVideo.previewUrl,
                    alt: `Превью видео: ${fondedVideo.name}`,
                  }
                : undefined
            }
          />
          <TimeLineControl ref={videoRef} showByStats />
        </VideoEditVideoWrapper>
        <AboutVideoEdit />
        <StatsDescription />
      </VideoEditContentWrapper>
      {/*<Settings />*/}
    </div>
  );
};
