import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconBaseProps } from '../../types/common';

interface VolumeProps extends TIconBaseProps {
  visible: boolean;
}

const Volume: FC<VolumeProps> = ({ theme, visible }) => {
  return (
    <svg
      width="28"
      height="24"
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5781 3.375C11.2812 2.67188 12.5 3.14062 12.5 4.17188V19.875C12.5 20.9062 11.2812 21.375 10.5781 20.6719L6.40625 16.5H1.625C0.96875 16.5 0.5 16.0312 0.5 15.375V8.625C0.5 8.01562 0.96875 7.5 1.625 7.5H6.40625L10.5781 3.375ZM21.5 0.9375C25.25 3.42188 27.5 7.54688 27.5 12C27.5 16.5 25.25 20.625 21.5 23.1094C20.9375 23.4375 20.2344 23.25 19.9062 22.7812C19.5781 22.2188 19.7188 21.5156 20.2344 21.1875C23.375 19.1719 25.2031 15.7031 25.2031 12.0469C25.2031 8.34375 23.375 4.875 20.2344 2.85938C19.7188 2.53125 19.5781 1.82812 19.9062 1.26562C20.2812 0.75 20.9844 0.609375 21.5 0.9375ZM23 12C23 15 21.4531 17.7188 18.9688 19.3594C18.4062 19.6875 17.7031 19.5 17.4219 18.9844C17.0938 18.4688 17.2344 17.7656 17.75 17.4375C19.625 16.2656 20.75 14.2031 20.75 12C20.75 9.84375 19.625 7.78125 17.75 6.60938C17.2344 6.28125 17.0938 5.57812 17.4219 5.0625C17.75 4.5 18.4531 4.35938 18.9688 4.6875C21.4531 6.32812 23 9.04688 23 12ZM16.3438 8.4375C17.6562 9.14062 18.5 10.5469 18.5 12C18.5 13.5 17.6562 14.9062 16.3438 15.6094C15.7812 15.9375 15.125 15.75 14.7969 15.1875C14.5156 14.625 14.7031 13.9688 15.2656 13.6406C15.8281 13.3125 16.25 12.7031 16.25 12C16.25 11.3438 15.8281 10.7344 15.2656 10.4062C14.7031 10.0781 14.5156 9.42188 14.7969 8.85938C15.125 8.29688 15.7812 8.10938 16.3438 8.4375Z"
        fill={visible ? theme.COLORS.ACCENT._100 : theme.COLORS.WHITE._100}
      />
    </svg>
  );
};
export default withTheme(Volume);
