import { PauseOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import Slider from 'antd/lib/slider';
import { forwardRef, useEffect, useState } from 'react';

import { VideoPlayFilled } from '../icons/video-play-filled';
import { VideoStop } from '../icons/video-stop';
import Volume from '../icons/volume';

type TProps = {
  play: boolean;
  togglePlay: () => void;
  controlsVisible: boolean;
};

const Wrapper = styled('div')`
  z-index: 2;
  gap: 20px;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
`;

const PlayButton = styled('div')`
  width: 70px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.COLORS.ACCENT._200};
  color: ${({ theme }) => theme.COLORS.WHITE._100};
  font-size: 20px;

  .icon {
    transform: translateX(3px);
  }
`;

const VolumeWrapper = styled('div')<{ visible: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  position: relative;

  svg {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .slider {
    height: 85px;
    top: -140px;
    display: ${({ visible }) => (visible ? 'block' : 'none')};
    z-index: 999;
    position: absolute;

    &::before {
      content: '';
      position: absolute;
      top: -20px;
      left: -10px;
      right: -10px;
      bottom: -20px;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: -1;
      border-radius: 5px;
    }

    .ant-slider-handle {
      opacity: 0;
      transition: opacity 0.3s ease-out;
    }

    .ant-slider-track {
      background-color: ${({ theme }) => theme.COLORS.ACCENT._200};
      border-radius: 2px;
    }

    .ant-slider-rail {
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 2px;
    }

    .ant-slider-handle {
      width: 5px;
      height: 5px;
    }
  }

  &:hover {
    .ant-slider-handle {
      opacity: 1;
    }
  }
`;

const SetVolume = styled('div')``;

export const Controls = forwardRef<HTMLVideoElement, TProps>(
  ({ play, togglePlay, controlsVisible }, videoRef) => {
    const [volume, setVolume] = useState(100);
    const [volumeVisible, setVolumeVisible] = useState(false);

    useEffect(() => {
      if (videoRef && 'current' in videoRef && videoRef.current) {
        videoRef.current.volume = volume / 100;
      }
    }, [volume]);

    const toggleVolume = () => {
      setVolumeVisible(!volumeVisible);
    };
    return (
      <Wrapper>
        <PlayButton onClick={togglePlay}>
          {play ? <VideoStop /> : <VideoPlayFilled className="icon" />}
        </PlayButton>
        <VolumeWrapper visible={volumeVisible}>
          <Slider
            className="slider"
            value={volume}
            onChange={setVolume}
            min={0}
            max={100}
            vertical
          />
          <SetVolume onClick={toggleVolume}>
            <Volume visible={volumeVisible} />
          </SetVolume>
        </VolumeWrapper>
      </Wrapper>
    );
  }
);
