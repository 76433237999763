import { useAppSelector } from '../index';

export const useCreateVideoSelector = () =>
  useAppSelector((state) => state.video.create);

export const useVideoListSelector = () =>
  useAppSelector((state) => state.video.list);

export const useVideoSelector = () =>
  useAppSelector((state) => state.video.byId);

export const useVideoViewedSelector = () =>
  useAppSelector((state) => state.video.viewed);

export const useLinksListSelector = () =>
  useAppSelector((state) => state.video.linksList);

export const useLinkStatSelector = () =>
  useAppSelector((state) => state.video.linkStat);
