import styled from '@emotion/styled';
import { Tooltip } from 'antd';
import { FC, useCallback } from 'react';

import { boolToText } from '../../../utils/common';
import { ArrowTopIcon } from '../../icons/arrow-top-icon';
import { TooltipQuestionIcon } from '../../icons/tooltip-question';
import { SORT_VALUES, TSortValue } from '../../table/types';
import { TAnalyticsDataType } from './types';

const TableHeaderColWrapper = styled.div<{
  sortable?: 'true' | 'false';
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

const LabelText = styled.span`
  color: ${({ theme }) => theme.COLORS.WHITE._500};
  font-size: 14px;
  font-weight: normal;
`;

const IconWrapper = styled('div')`
  width: 12px;
  margin-right: 2px;
  margin-top: 2px;
`;

const TopIconWrapper = styled('div')<{
  sorted?: (typeof SORT_VALUES)[number];
}>`
  width: 16px;
  height: 16px;

  svg {
    display: ${({ sorted }) => (!sorted ? 'none' : 'block')};
    width: 100%;
    transform: rotate(${({ sorted }) => (sorted === 'asc' ? '180deg' : '0')});
  }
`;

type TProps = {
  label?: string;
  sorted?: TSortValue;
  tooltip?: string;
  sortable?: boolean;
  setSorted?: (
    newValue: Partial<Record<keyof TAnalyticsDataType, TSortValue>>
  ) => void;
  columnKey: keyof TAnalyticsDataType;
};

export const TableHeader: FC<TProps> = ({
  label,
  sorted,
  setSorted,
  tooltip,
  columnKey,
  sortable,
}) => {
  const handleClick = useCallback(() => {
    if (sortable) {
      let nextValue: TSortValue;
      const currentIndex = sorted ? SORT_VALUES.indexOf(sorted) : -1;

      if (currentIndex === SORT_VALUES.length - 1) {
        nextValue = SORT_VALUES[0];
      } else {
        nextValue = SORT_VALUES[currentIndex + 1];
      }

      setSorted?.({
        [columnKey]: nextValue,
      });
    }
  }, [sortable, sorted, columnKey, setSorted]);

  return (
    <TableHeaderColWrapper
      onClick={handleClick}
      sortable={boolToText(sortable)}
    >
      {tooltip && (
        <Tooltip title={tooltip} placement="bottom">
          <IconWrapper>
            <TooltipQuestionIcon />
          </IconWrapper>
        </Tooltip>
      )}
      {label && <LabelText>{label}</LabelText>}
      <TopIconWrapper sorted={sorted}>
        <ArrowTopIcon />
      </TopIconWrapper>
    </TableHeaderColWrapper>
  );
};
